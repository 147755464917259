<template>
    <div class="surface-card p-6 border-1 surface-border">
        <data-table-view
            endpoint="companies"
            :headline="$t('Companies')"
        >
            <template slot="actions">
                <router-link to="/companies/create">
                    <Button
                        :label="$t('Create new company')"
                        class="p-button-primary p-button-sm"
                    />
                </router-link>
            </template>
            <Column field="name" :header="$t('Name')" sortable/>
            <Column field="location" :header="$t('Location')" sortable/>
            <Column field="createdAt" :header="$t('Created at')" sortable>
                <template #body="slotProps">
                    {{slotProps.data.createdAt|formatDate('LLL')}}
                </template>
            </Column>

            <Column header="" :styles="{'text-align': 'right'}">
                <template #body="slotProps">
                    <router-link
                        :to="'/companies/' + slotProps.data.id"
                        v-if="slotProps.data.userCanUpdate"
                    >
                        <i class="pi pi-pencil"></i>
                    </router-link>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import Column from 'primevue/column';
    import DataTableView from "@/components/DataTableView";
    import InputText from "primevue/inputtext/InputText";
    import TriStateCheckbox from "primevue/tristatecheckbox";

    export default {
        components: {
            InputText,
            Button,
            DataTableView,
            Column,
            TriStateCheckbox
        },
        methods: {}
    }
</script>
